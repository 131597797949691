import React from 'react';
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Layout from '../components/shared/Layout'
import SEO from '../components/shared/SEO'
import Container from '../components/shared/Container'

class IndexPage extends React.Component {

  render() {
    
    return (
      <Layout>

        <SEO title="Home" />          
        
        <Section style={tw`bg-primary`}>
          <Container>
            <img src={"/logo.png"} alt="logo" height="50"/>
            <Title>The Entofarmers Club</Title>
            <Subtitle>Providing a source of sustainable protein for the world.</Subtitle>
            <Description>Based on Controlled Environment insect production which is distributed, open source and transparent for all.</Description>
          </Container>
        </Section>

        <Section>
          <Container>
            <SectionTitle>A NEW WAY OF FEEDING THE WORLD</SectionTitle>
            <p><strong>Eating insects</strong> is part of the solution to the world food challenge. We have to change our own eating pattern and use insect as a source of protein.</p>
            <br />
            <p>The Open Insect Farming Collective provides the tools to produce insects for feed. By sharing the knowledge, tools and data for the production of insects we create collective knowlege and understanding.</p>
            <p>Become an insect farmer <strong>today!</strong></p>
          </Container>
        </Section>

        <Section>
          <Container>
            <SectionTitle>COMPLETELY OPEN SOURCE</SectionTitle>
            <p><strong>Transparency</strong> is key in gaining trust and momentum for adding insect protein to our daily menu. The OIF Collective products and services are completely open source</p>
            
            <ColumnWrapper>
              <ColumnHalf>
                <h4 style={tw`mb-0 text-lg text-black text-bold`}>Open software</h4>
                <p style={tw`mt-0 mb-8`}>Using open source tools and libraries</p>

                <h4 style={tw`text-lg text-black text-bold`}>Open data</h4>
                <p style={tw`mt-0 mb-8`}>Communication using open standards and open data</p>

                <h4 style={tw`mb-0 text-lg text-black text-bold`}>Open community</h4>
                <p style={tw`mt-0 mb-8`}>An open community of insect farmers</p>
              </ColumnHalf>
              <ColumnHalf>
                <h4 style={tw`mb-0 text-lg text-black text-bold`}>Data sharing</h4>
                <p style={tw`mt-0 mb-8`}>Share all data streams</p>

                <h4 style={tw`mb-0 text-lg text-black text-bold`}>Open collaboration</h4>
                <p style={tw`mt-0 mb-8`}>Open collaboration and project development</p>

                <h4 style={tw`mb-0 text-lg text-black text-bold`}>Open source license</h4>
                <p style={tw`mt-0 mb-8`}>All code published with an open source license</p>
              </ColumnHalf>
            </ColumnWrapper>
          </Container>
        </Section>

        <Section style={tw`bg-primary`}>
          <Container>
            <SectionTitle>A DISTRIBUTED &amp; CONNECTED ARCHITECTURE</SectionTitle>
            <p>Each Open Insect Farm is part of the collective network. By monitoring each farm in realtime, sharing this data and trigger actions based on configurations, we gradually create the best breeding conditions for each farm.</p>
            <p>The OIF Collective service is a technological backend service with the beste open source technology:</p>
            
            <h4>Open software</h4>
            <ul style={tw`list-none`}>
              <li>docker</li>
              <li>nodejs</li>
              <li>Typescript</li>
              <li>typeORM</li>
              <li>gitlab</li>
              <li>Tensorflow</li>
              <li>TimescaleDB</li>
            </ul>

            <br />
            <br />
            <ColumnWrapper>
              <Column>
                <h4 style={tw`text-lg text-black text-bold uppercase`}>DISTRIBUTED FARMING</h4>
                <p class="is-size-6">Each farm is connected to the OIF Architecture. All data is synchronized and stored in a collective database. This makes it possible to perfom big data analysis and create the best breeding conditions.</p>
              </Column>
              <Column>
                <h4 style={tw`text-lg text-black text-bold uppercase`}>CONNECTED ARCHITECTURE</h4>
                <p>Each farm is connected by default. Each farm, container and create has sensors and actuators to monitor and control the breeding process. By using socket connection technology, each farm has a realtime connection.</p>
              </Column>
              <Column>
                <h4 style={tw`text-lg text-black text-bold uppercase`}>MACHINE LEARNING FOR THE BEST FARMERS</h4>
                <p>We use Machine Learning and AI to monitor and control the breeding processes. By combining all data streams of all farms around the globe we create the best breeding algoritms based on the local conditions.</p>
              </Column>
            </ColumnWrapper>
          </Container>
        </Section>

        <Section>
          <Container>
            <SectionTitle>THE POWER OF THE COLLECTIVE</SectionTitle>
            <p>The OIF Collective is focused on sharing our insect farming knowledge. By making useofthe wisdom of the crowds we can create the best breeding conditions for our protein of the future.</p>
            <p>Become an insect farmer today!</p>
            <br /><br />
            <Link to={'/'}>Start your farm &rarr;</Link>
          </Container>
        </Section>
      </Layout>
    )
  }
}

// Component styles
const Section = styled.section`${tw`py-32 text-center text-xl`}`
const Title = styled.h1`${tw`text-3xl lg:text-6xl`}`
const Subtitle = styled.h2`${tw`text-xl lg:text-4xl font-thin`}`
const Description = styled.h3`${tw`text-lg lg:text-2xl font-thin`}`
const SectionTitle = styled.h3`${tw`uppercase `}`
const ColumnWrapper = styled.div`${tw`flex flex-wrap`}`
const Column = styled.div`${tw`w-full sm:w-1/2 md:w-1/3 mb-16`}`
const ColumnHalf = styled.div`${tw`w-full sm:w-1/2 mb-16`}`

export default IndexPage;

export const pageQuery = graphql`
  query {
    projects: allMarkdownRemark (
      filter: {
        frontmatter: { 
          type: { eq: "project" },
          draft: { ne: true } 
        }
      },
      limit: 6
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
          }
        }
      }
    }

    articles: allFile (
      sort: {order: DESC, fields: childMarkdownRemark___frontmatter___date},
      filter: {
        internal: {
          mediaType: { eq: "text/markdown" }
        },
        sourceInstanceName: { eq: "articles" },
        childMarkdownRemark: {
        	frontmatter: {
        		draft: {
        			ne: true
        		}
        	}
        }
      }
    ) {
      edges {
        node {
          internal {
            mediaType
          }
          sourceInstanceName
          childMarkdownRemark {
            id
            timeToRead
            excerpt(pruneLength: 80)
            html
            frontmatter {
              title
              path
              date
            }
          }
        }
      }
    }
  }
`;
